.ratingRow {
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  margin: 5px 0px;
  border: grey;
  border-top-style: dotted;

  &.rr-1 {
    padding-bottom: 5px;
  }
}

.ant-modal-wrap {
  z-index: 1040;
}

.rowTitle {
  font-weight: bold;
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.currentFinancialRatingBox {
  
  .no-data{
    height: 3rem;
    min-width: 200px;
  }
  .rating-container {
    border: 1px solid #b6b6b6;
  }

  .rating-container>div:last-child {
    height: 34.4px !important;
  }
}

.ant-popover-inner {
  border: 1px solid #b6b6b6;
}

.ant-popover-arrow {
  border-left: 1px solid #b6b6b6 !important;
  border-bottom: 1px solid #b6b6b6 !important;
}