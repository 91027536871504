@import "../../../../../assets/sass/variables";
h4 {
  margin: 0;
}

.related-notifications-list {
  border: unset;
  width: 100%;
  span.ant-menu-title-content {
    display: inline-flex;
    align-items: flex-start;
    width: inherit;
    .relatedNotificationsIcon {
      line-height: 0;
    }
  }
  .empty-item {
    margin: 25px auto;
  }
  .action-item .text {
    font-size: 12px;
    line-height: 1;
    width: 100%;
    padding-bottom: 5px;
    margin-left: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    .sub-text {
      margin-top: 0;
    }
    span.message {
      padding: 8px 0;
      white-space: break-spaces;
      line-height: 1.5;
    }
  }
  li.ant-menu-item.action-item.d-flex.w-100 {
    padding: 0;
  }
  .ant-btn {
    height: 30px;
    background: transparent !important;
    box-shadow: unset;
    color: $primary-bg-color !important;
    &:hover {
      border: none !important;
    }
  }
}

// .roadmapDropdown {
//   .ant-dropdown-menu .ant-dropdown-menu-item svg {
//     fill: red;
//     path {
//       fill: red;
//     }
//   }
//   // .ant-dropdown-menu {
//   //   padding: unset;
//   // }
// }

//////////////////////////////////////BREADCRUMBS///////////////////////////////////
// .roadmapBreadcrumbsContainer {
//   margin-top: 15px;
//   width: 95%;
//   // max-width: 700px;
//   // margin: auto;
.customHeader .dashboardTitleContainer .anticon:hover{
  color:$primary-bg-color;
}
.customHeader[data-test="tracked-item-dashboard"]{
  align-items: flex-end !important;
}
[data-test="tracked-item-dashboard"] > div:last-child .filterDropdown .ant-select-selector{
height:40px !important;
}
.ant-breadcrumb.breadcrum-bg{
margin-top:0.6rem !important;
}
.breadcrum-bg {
  margin-top: 10px;
  width: fit-content;
  max-width: 100%;
  // width: 95%;
  background: #d9edec;
  display: flex;
  align-items: center;
  // height: 50px;
  height: 55px;
  border-left: 1px solid #79a4a2;
  border-top: 1px solid #79a4a2;
  border-bottom: 1px solid #79a4a2;
  .breadcrumb-item {
    display: inline-flex;
    align-items: center;
    // height: 50px;
    height: 55px;
    justify-content: center;
    flex: 0 100%;
    max-width: 100%;
    min-width: 0px;
    .breadcrumb-label {
      text-decoration: none;
      color: #325165;
      font-size: 11px;
      flex: 1;
      padding-left: 10px;
      width: max-content;
      text-align: center;
      max-width: 100%;
    }
  }
  & > :first-child > .breadcrumb-item{
  margin-left:0px;
  }
}
.arrow {
  border: solid #79a4a2;
  display: inline-block;
  // padding: 14px;
  padding: 15px;
  border-width: 0 1px 1px 0;
  transform: translateY(24px) rotate(-45deg) skew(-13deg, -13deg)
    translateX(28px);
  background-color: #d9edec;
}
// }

//////////////////////////////////////////////////////////////ROADMAP DESIGN/////////////////////////////////
@mixin spinnerColor($color) {
  .ant-spin-spinning {
    color: $color;
    margin-left: 8px;
    .ant-spin-dot {
      font-size: 15px;
    }
  }
}

.Roadmap {
  .groupNode {
    // .treeTitle {
    //   color: #398f84;
    // }
    @include spinnerColor(#398f84);
  }

  .companyNode {
    .ant-tree-switcher {
      border: 1px solid #3690ff;
      background: #3690ff !important;
    }
    .ant-tree-switcher::after {
      border-color: #3690ff;
    }
    @include spinnerColor(#3690ff);
  }

  .siteNode {
    .ant-tree-switcher {
      border: 1px solid #006699;
      background: #006699 !important;
    }
    .ant-tree-switcher::after {
      border-color: #006699;
    }
    @include spinnerColor(#006699);
  }

  .categoryNode {
    .ant-tree-switcher {
      border: 1px solid #8f23b5;
      background: #8f23b5 !important;
    }
    .ant-tree-switcher::after {
      border-color: #8f23b5;
    }
    @include spinnerColor(#8f23b5);
  }

  .subcategoryNode {
    .ant-tree-switcher {
      border: 1px solid #e06a14;
      background: #e06a14 !important;
    }
    .ant-tree-switcher::after {
      border-color: #e06a14;
    }
    @include spinnerColor(#e06a14);
  }

  .trackedItemNode {
    .ant-tree-node-content-wrapper {
      margin-left: -25px !important;
    }
    .ant-tree-indent-unit::before,
    .ant-tree-switcher::after {
      border-color: #149ebc;
    }
    @include spinnerColor(#149ebc);
  }

  .ant-empty {
    margin-top: 20px;
    padding: 20px 0;
    border: 1px ridge;
    background: white;
  }

  .ant-tree {
    color: rgba(0, 0, 0, 0.85);
    font-size: 15px;
    border: 1px solid #b6b6b6;
    border-radius: 6px;
    margin-top: 13px;
    .ant-tree-treenode {
      padding: 0;
      width: 100%;
      display: flex;
      align-items: center;
    }
    .ant-tree-switcher {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 7px 10px 0 30px;
      color: white;
      border-radius: 14px;
      width: 24px;
      height: 24px;
      z-index: 2;
      .ant-tree-switcher-icon {
        font-size: small;
      }
    }
    .ant-tree-node-content-wrapper {
      padding: 7px 0;
      cursor: auto;
      &:hover {
        background: transparent;
      }
    }
  }

  .parentNode.ant-tree-treenode.ant-tree-treenode-switcher-open {
    z-index: 1;
    background: #f7fbfd;
    position: relative;
  }

  .ant-tree .ant-tree-node-content-wrapper.ant-tree-node-selected {
    background-color: transparent;
  }

  .treeTitle {
    font-weight: bold;
    color: black;
    cursor: pointer;
  }

  .parentNode {
    border-top: 1px solid #b6b6b6;
    padding: 0 !important;
    .ant-tree-switcher {
      border: 1px solid lightgray;
      border-radius: 14px;
      width: 27px;
      height: 27px;
      color: #2b3b4a;
      margin: 8px 11px 4px 11px;
      background: white !important;
      &::after {
        display: none;
      }
      .ant-tree-switcher-icon {
        font-size: x-small;
      }
    }
    span.ant-tree-switcher.ant-tree-switcher_open {
      color: #398f84;
    }
    span.ant-tree-node-content-wrapper {
      padding: 12px 25px 4px 25px !important;
    }
    .ant-tree-node-selected {
      background-color: #f7fbfd;
    }
  }

  span.ant-tree-node-content-wrapper.ant-tree-node-content-wrapper-close.ant-tree-node-selected {
    background-color: transparent;
  }

  //background of expanded class
  .ant-tree .ant-tree-treenode:not(.parentNode) {
    background: rgba(241, 249, 252, 0.6);
  }

  // vertical line below expandable nodes
  // :not(.ant-tree-treenode.ant-tree-treenode-switcher-close.ant-tree-treenode-leaf-last)
  // .ant-tree-treenode.ant-tree-treenode-switcher-open
  // .ant-tree-treenode:not(.ant-tree-treenode-switcher-close.ant-tree-treenode-leaf-last)
  // span.ant-tree-indent-unit.ant-tree-indent-unit-start::before,
  // span.ant-tree-indent-unit.ant-tree-indent-unit-start.ant-tree-indent-unit-end::before
  .ant-tree-indent-unit::before {
    margin-left: 36px;
    margin-top: -36px;
    height: 39px;

    position: absolute;
    border-right: 1px solid;
    content: "";
    // &:first-child {
    //   height: 0;
    // }
  }

  .ant-tree-indent-unit {
    &:first-child::before {
      border-color: #3690ff;
      margin-left: 75px;
    }
    &:nth-child(2)::before {
      border-color: #006699;
    }
    &:nth-child(3)::before {
      border-color: #8f23b5;
    }
    &:nth-child(4)::before {
      border-color: #e06a14;
    }
  }

  // 1st column background color
  .ant-tree .ant-tree-treenode::after {
    left: 0;
    position: absolute;
    margin-top: -2px;
    border-left: 25px solid;
    border-right: 25px solid;
    border-bottom: 20px solid;
    border-top: 20px solid;
    border-color: #398f83;
    content: "";
    z-index: 1;
  }

  // grey background on parent collapsed
  .parentNode.ant-tree-treenode.ant-tree-treenode-switcher-close::after {
    margin-top: 10px;
    border: 25px solid #f0f0f0;
  }

  //Extra padding before border
  .parentNode.ant-tree-treenode.ant-tree-treenode-switcher-close {
    padding-bottom: 10px !important;
  }
  // .ant-tree-treenode.ant-tree-treenode-leaf-last:not(.parentNode) {
  //   padding-bottom: 12px;
  // }
  .ant-tree-treenode.ant-tree-treenode-switcher-open.ant-tree-treenode-leaf-last {
    padding-bottom: 0;
  }

  // left line before expand/collapse icon
  .ant-tree-switcher::after {
    content: " ";
    width: 17px;
    border-bottom: 1px solid;
    height: 9%;
    position: absolute;
    left: -18px;
  }

  .ant-tree-indent-unit,
  span.ant-tree-indent-unit.ant-tree-indent-unit-start {
    &:first-child {
      width: 63px;
    }
  }

  .ant-tree-switcher-noop {
    color: transparent !important;
  }

  .ant-tree-show-line .ant-tree-switcher {
    background: transparent;
  }
}
.name-tooltip-content{
  display: block;
  white-space: normal;
  max-width: 85vw;
}

.customHeader .name-tooltip {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}