@import "../../../../assets/sass/variables";

.fixedColumnListing {
  // .ant-table table {
  //   border-spacing: unset;
  // }
  .ant-table-wrapper {
    .ant-table-tbody > tr > td {
      border: 1px solid #0302290d !important;
      background: white;
      padding: 0 0.6rem;
    }
  }
  th.ant-table-cell.ant-table-cell-fix-left.ant-table-cell-fix-left-last,
  th.ant-table-cell.ant-table-row-expand-icon-cell.ant-table-cell-fix-left {
    background-color: $bg-color !important;
  }

  td.ant-table-cell-ellipsis.ant-table-cell-fix-left-last,
  td.ant-table-cell-ellipsis.ant-table-cell-fix-right-first {
    overflow: hidden;
  }

  .ant-table-cell-ellipsis.ant-table-cell-fix-left-last
    .ant-table-cell-content {
    overflow: unset;
  }

  .ant-table-wrapper .ant-table tr td:first-child {
    box-shadow: 3px 1px 4px rgba(3, 2, 41, 0.08);
  }

  .ant-table-wrapper .ant-table-thead tr > th {
    text-align: center;
  }

  .ant-table-tbody
    > tr:not(.ant-table-expanded-row):not(.ant-table-row-selected) {
    &:hover {
      // box-shadow: 0 0 10px #c5c5c5 !important;
      > td {
        border-color: #0302290d !important;
        background: white !important;
        box-shadow: unset !important;
      }
    }
    > td:not(:first-child) {
      text-align: center;
    }
  }
}

.rowStyle {
  .expandedCollapseIcon:hover {
    color: unset !important;
  }
  .ant-table-tbody
    > tr:not(.ant-table-expanded-row):not(.ant-table-row-selected):not(
      .boldRow
    ):hover {
    box-shadow: none !important;
    > td {
      &:first-child {
        background: $primary-bg-color !important;
        .summary_actions_buttons {
          display: inherit;
        }
        // :not(.ant-table-row-expand-icon),
        :not(.summary_actions_buttons svg) {
          color: #fff;
        }
      }
      &:not(:first-child) {
        background: #17a99a1a !important;
      }
    }
  }
}

.boldRow {
  font-weight: bold;
}

.nestedRow {
  .ant-table-cell-content {
    margin-left: 1.5rem;
  }
  span.ant-table-row-indent.indent-level-0 {
    padding-left: 15px !important;
  }
}

.expandedRow:not(.nestedRow) {
  color: #17a99a;
}

.expandedCollapseIcon {
  margin-right: 12px;
  margin-left: 10px;
  font-size: smaller;
  &:hover {
    color: white;
  }
}

.summary_actions_buttons {
  display: none;
  float: right;
  margin: 0 10px;
  // background: white;
  // padding: 0 5px;
}

.selectedRow {
  box-shadow: none !important;
  > td {
    &:first-child {
      background: $primary-bg-color !important;
      .summary_actions_buttons {
        display: inherit;
      }
      :not(.summary_actions_buttons svg) {
        color: #fff;
      }
    }
    &:not(:first-child) {
      background: #17a99a1a !important;
    }
  }
}

.netValue {
  border-top: 2px solid black;
}

.reportColumnValue {
  padding: 10px 2px;
}

.reportFilterDropdown {
  width: 175px;
  .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 40px !important;
  }
}
.fixedColumnListing[data-test="financial-reports-table"] {
  margin-top: 15px;
}

.financialsPopup {
  overflow: auto;
  .fixedCol {
    position: sticky;
    left: 0;
    background: white;
  }
}
.loadDataClass {
  .ant-table-wrapper
    .ant-table-tbody
    tr.nestedRow
    .ant-table-cell-ellipsis.ant-table-cell-fix-left-last
    .ant-table-cell-content
    > span:first-child,
  .ant-table-wrapper
    .ant-table-tbody
    tr.nestedRow.selectedRow
    .ant-table-cell-ellipsis.ant-table-cell-fix-left-last
    .ant-table-cell-content
    > span:first-child {
    overflow: visible !important;
    white-space: initial !important;
    max-width: 100% !important;
  }
  .ant-table-wrapper
    .ant-table-tbody
    tr.nestedRow
    .ant-table-cell-ellipsis.ant-table-cell-fix-left-last
    .ant-table-cell-content,
  .ant-table-wrapper
    .ant-table-tbody
    tr.nestedRow.selectedRow
    .ant-table-cell-ellipsis.ant-table-cell-fix-left-last
    .ant-table-cell-content {
    display: inline-flex !important;
    align-items: center !important;
    width: calc(100% - 40px) !important;
    justify-content: space-between !important;
  }
}

.reports-dropdown-container {
  display: flex;
  position: relative;
  .ant-tabs-nav {
    margin-top: 26px !important;
    width: 72%;
  }
  .ant-tabs .ant-tabs-nav .ant-tabs-tab {
    width: auto;
    min-width: 162px;
  }
  th.ant-table-cell {
    text-wrap: auto !important;
  }
}
